@import "./src/assets/scss/main.scss";


































































































.adminHeaderWrapper {}

.container {}

.adminHeader {
    margin-top: 27px;
    margin-bottom: 40px;

    &__btns {}

    &__btn {
        display: flex;
        justify-content: end;
    }
}

.day-item {
    height: 141px;
    background: #F5F5F5;
    border-radius: 13px;
    padding: 28px 10px 20px 37px;

    &__date {
        line-height: 1;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
    }

    &__btns {}
}

.btn-oval {}

.container {
    max-width: 1340px;
}

.black {
    background: #000;
    color: #fff;
}
.btnOrBr{
    background: #fff;
    border:2px solid $orange;
    color:$orange;
}
